import PropTypes from "prop-types";
import { block } from "bem-cn";

import Link from "components/Link";
import RedArrowRightIcon from "components/Icons/redArrow";

import "./style.scss";

const cn = block("main-buttons-group");

const ButtonsGroup = ({ buttonsData, className, buttonClassName }) => {
  return (
    <div className={cn("wrap").mix(className)}>
      {buttonsData.map((button) => (
        <Link
          key={`${button.link}_${button.text}`}
          isOutside
          isButton
          isRed={button.color === "red"}
          href={button.link}
          className={cn("button").mix(buttonClassName)}
        >
          {button.text}
          {button.withIcon && <RedArrowRightIcon />}
        </Link>
      ))}
    </div>
  );
};

ButtonsGroup.propTypes = {
  className: PropTypes.object,
  buttonClassName: PropTypes.object,
  buttonsData: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      download: PropTypes.bool,
      color: PropTypes.string,
      withIcon: PropTypes.bool,
    })
  ),
};

export default ButtonsGroup;
