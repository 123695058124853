export const menu = [
	{
		title: 'GIAnalyzer',
		footer: true,
		menu: [
			{
				name: 'Get free trial',
				link: '/free-trial/',
			},
			{
				name: 'Buy license',
				link: '/pricing/',
			},
			{
				name: 'Student license',
				link: '/students/',
			},
			{
				name: 'Downloads',
				link: '/downloads/',
			},
		],
	},
	{
		title: 'Modules',
		header: true,
		footer: true,
		menu: [
			{
				name: 'Bonds',
				link: '/bonds/',
			},
			{
				name: 'FX Derivatives',
				link: '/fx-derivatives/',
			},
			{
				name: 'Interest Rate Derivatives',
				link: '/interest-rate-derivatives/',
			},
			{
				name: 'Inflation Derivatives',
				link: '/inflation-derivatives/',
			},
			{
				name: 'Equity Derivatives',
				link: '/equity-derivatives/',
			},
			{
				name: 'Risk and Performance Measures',
				link: '/risk-and-performance-measures/',
			},
			{
				name: 'Interest Rates',
				link: '/interest-rates/',
			},
			// {
			//   name: "Calculation Tools",
			//   link: "/calculation-tools/",
			//   includeFooter: true,
			// },
		],
	},
	{
		title: 'Pricing',
		link: '/pricing/',
		header: true,
	},
	{
		title: 'Knowledge base',
		header: true,
		footer: true,
		menu: [
			{
				name: 'Documentation',
				link: '/docs/',
			},
			// {
			// 	name: 'Video tutorials',
			// 	link: '/video-tutorials/',
			// },
			{
				name: 'FAQ',
				link: '/faq/',
			},
			{
				name: 'Downloads',
				link: '/downloads/',
				includeFooter: true,
			},
			{
				name: 'For students',
				link: '/students/',
				includeFooter: true,
			},
		],
	},
	{
		title: 'Company',
		header: true,
		footer: true,
		menu: [
			{
				name: 'About us',
				link: '/about-company/',
			},
			{
				name: 'Note from the founder',
				link: '/note-from-founder/',
			},
			{
				name: 'Contact us',
				link: '/contact/',
			},
		],
	},
];

export const sidebarDocsMenu = [
	{
		...menu
			.find((item) => item.title === 'Knowledge base')
			.menu.find((menuItem) => menuItem.name === 'Documentation'),
	},
	{
		name: 'User Guide',
		menu: [
			{
				title: 'Introduction',
				subMenu: [
					{
						name: 'General',
						link: '/docs/general/',
					},
					{
						name: 'System Requirements',
						link: '/docs/system-requirements/',
					},
					{
						name: 'Installation',
						link: '/docs/install/',
					},
					{
						name: 'License Activation',
						link: '/docs/activate-license/',
					},
					{
						name: 'Uninstallation',
						link: '/docs/uninstall/',
					},
					{
						name: 'Installing Updates',
						link: '/docs/install-updates/',
					},
					{
						name: 'Toolbar',
						link: '/docs/toolbar/',
					},
				],
			},
			,
			{
				title: 'Financial Instruments',
				subMenu: [
					{
						name: 'Instrument Coverage',
						link: '/docs/instrument-coverage/',
					},
					{
						name: 'Bonds',
						link: '/docs/bonds/',
					},
					{
						name: 'FX Derivatives',
						link: '/docs/fx-derivatives/',
					},
					{
						name: 'Interest Rate Derivatives',
						link: '/docs/interest-rate-derivatives/',
					},
					,
					{
						name: 'Inflation Derivatives',
						link: '/docs/inflation-derivatives/',
					},
					{
						name: 'Equity Derivatives',
						link: '/docs/equity-derivatives/',
					},
				],
			},
			{
				title: 'Additional Functions',
				subMenu: [
					{
						name: 'Risk and Performance Measures',
						link: '/docs/risk-and-performance-measures/',
					},
					{
						name: 'Interest Rates',
						link: '/docs/interest-rates/',
					},
					{
						name: 'Other Functions',
						link: '/docs/other-functions/',
					},
				],
			},
			{
				title: 'Calculation Tools',
				subMenu: [
					{
						name: 'Calculate Selection',
						link: '/docs/calculate-selection/',
					},
					{
						name: 'Calculate Function',
						link: '/docs/calculate-function/',
					},
					{
						name: 'Saving Options',
						link: '/docs/saving-options/',
					},
				],
			},
			{
				title: 'Excel Workbooks',
				subMenu: [
					{
						name: 'Financial Instruments',
						link: '/docs/workbooks-financial-instruments/',
					},
					{
						name: 'Risk and Performance Measures',
						link: '/docs/workbooks-risk-and-performance-measures/',
					},
					{
						name: 'Interest Rates',
						link: '/docs/workbooks-interest-rates/',
					},
				],
			},
		],
	},
	{
		name: 'Functions Description',
		menu: [
			{
				title: 'Bonds',
				subMenu: [
					{
						name: 'Parameters',
						link: '/docs/bonds/parameters/',
					},
					{
						name: 'Functions',
						link: '/docs/bonds/functions/',
					},
				],
			},
			{
				title: 'FX Derivatives',
				subMenu: [
					{
						name: 'Parameters',
						link: '/docs/fx-derivatives/parameters/',
					},
					{
						name: 'Functions',
						link: '/docs/fx-derivatives/functions/',
					},
				],
			},
			{
				title: 'Interest Rate Derivatives',
				subMenu: [
					{
						name: 'Parameters',
						link: '/docs/interest-rate-derivatives/parameters/',
					},
					{
						name: 'Functions',
						link: '/docs/interest-rate-derivatives/functions/',
					},
				],
			},
			{
				title: 'Inflation Derivatives',
				subMenu: [
					{
						name: 'Parameters',
						link: '/docs/inflation-derivatives/parameters/',
					},
					{
						name: 'Functions',
						link: '/docs/inflation-derivatives/functions/',
					},
				],
			},
			{
				title: 'Equity Derivatives',
				subMenu: [
					{
						name: 'Parameters',
						link: '/docs/equity-derivatives/parameters/',
					},
					{
						name: 'Functions',
						link: '/docs/equity-derivatives/functions/',
					},
				],
			},
			{
				title: 'Risk and Performance Measures',
				subMenu: [
					{
						name: 'Parameters',
						link: '/docs/risk-and-performance-measures/parameters/',
					},
					{
						name: 'Functions',
						link: '/docs/risk-and-performance-measures/functions/',
					},
				],
			},
			{
				title: 'Interest Rates',
				subMenu: [
					{
						name: 'Parameters',
						link: '/docs/interest-rates/parameters/',
					},
					{
						name: 'Functions',
						link: '/docs/interest-rates/functions/',
					},
				],
			},
			{
				title: 'Other Functions',
				subMenu: [
					{
						name: 'Parameters',
						link: '/docs/other-functions/parameters/',
					},
					{
						name: 'Functions',
						link: '/docs/other-functions/functions/',
					},
				],
			},
		],
	},
];

export const sidebarFaqMenu = [
	{
		name: 'FAQ',
		link: '/faq/',
	},
	{
		name: 'General',
		menu: [
			{
				name: 'Installation',
				link: '/faq/installation/',
			},
			{
				name: 'Free Trial',
				link: '/faq/free-trial/',
			},
			{
				name: 'Subscription',
				link: '/faq/subscription/',
			},
			{
				name: 'Payments',
				link: '/faq/payments/',
			},
		],
	},
	{
		name: 'Functions ',
		menu: [
			{
				name: 'Bonds',
				link: '/faq/bonds/',
			},
			{
				name: 'FX Derivatives',
				link: '/faq/fx-derivatives/',
			},
			{
				name: 'Interest Rate Derivatives',
				link: '/faq/interest-rate-derivatives/',
			},
			{
				name: 'Inflation Derivatives',
				link: '/faq/inflation-derivatives/',
			},
			{
				name: 'Equity Derivatives',
				link: '/faq/equity-derivatives/',
			},
			{
				name: 'Risk and Performance Measures',
				link: '/faq/risk-and-performance-measures/',
			},
			{
				name: 'Interest Rates',
				link: '/faq/interest-rates/',
			},
		],
	},
	{
		name: 'Troubleshooting',
		menu: [
			{
				name: 'Errors',
				link: '/faq/errors/',
			},
		],
	},
];

export const subFooterMenu = [
	{
		name: 'Privacy\xa0Policy',
		link: '/privacy-policy/',
	},
	{
		name: 'Cookie\xa0Declaration',
		link: '/cookie-declaration/',
	},
	{
		name: 'Terms\xa0&\xa0Conditions',
		link: '/terms-and-conditions/',
	},
	{
		name: 'License\xa0Agreement',
		link: '/eula/',
	},
];

export const emailRegex =
	/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const okText = 'OK';
export const currencyText = '€';
export const versionText = 'version';
export const currentVersionText = 'Current' + ' ' + versionText;
export const onlineVersionText = 'Online' + ' ' + versionText;
export const offlineVersionText = 'Offline' + ' ' + versionText;
export const freeTrialText = 'Free trial';
export const getFreeTrialText = 'Get free trial';
export const seePricingText = 'See pricing';
export const buyText = 'Buy';
export const totalPriceText = 'Total Price';
export const taxesText = 'Taxes may apply';
export const for1UserText = 'for 1 user';
export const yearText = 'year';
export const yearsText = yearText + 's';
export const copyrightText = `© ${new Date().getFullYear()} Gamma Impact Ltd.`;
export const trademarksText =
	'Microsoft and the Office logo are trademarks or registered trademarks of Microsoft Corporation.';
export const supportExcelText =
	'Supported for Microsoft Excel 2010 – 2019, Office 365,';
export const windows710Text = 'Windows 7 – 10';
export const getStartedText = 'Get started for free';
export const downloadNowText = 'Download now and try free for 30 days.';
export const fullyFeaturedVersionText =
	'Fully featured version, no credit card required.';
export const sendText = 'Send';
export const closeText = 'Close';
export const menuText = 'Menu';
export const notSelectedText = 'select at least one module';
export const footerDescriptionText = [
	'GIAnalyzer Add-in for Microsoft Excel is a product of Gamma Impact Ltd.',
	'Our company provides consulting services and training in financial instruments and market risk management since 2009.',
];
export const cookiesText =
	'We use cookies to give you the best possible experience on our website, analyze website traffic and assist in our marketing efforts. By continuing to browse or by clicking “Accept” you agree to our use of cookies as described in our';

export const invalidEmailText = 'Invalid email address';
export const notFillFieldText = 'Please fill in the field';
export const notFindNumberText = 'Invalid number';

export const text404 = {
	404: '404',
	pageNotFoundText: 'Sorry, page not found',
	notExistPageText:
		'The page you are looking for was moved or no longer exists.',
	checkUrlText:
		'Please check if the URL entered into the web browser is correct and try again.',
};

export const offlineOnlineVersionSource = [
	{
		id: 'online-version',
		text: onlineVersionText,
		link: '/clickonce/setup.exe',
		color: 'red',
	},
	{
		id: 'offline-version',
		text: offlineVersionText,
		link: '/clickonce/GIAnalyzer_offline.zip',
	},
];

export const freeTrialPricingSource = [
	{
		text: getFreeTrialText,
		link: '/free-trial/',
		color: 'red',
	},
	{ text: seePricingText, link: '/pricing/', withIcon: true },
];
