import { graphql } from 'gatsby';
import { block } from 'bem-cn';

import {
	currentVersionText,
	offlineOnlineVersionSource,
} from 'assets/constants';

import Layout from 'layouts/';
import Heading from 'components/Heading';
import Container from 'components/Container';
import Paragraph from 'components/Paragraph';
import Title from 'components/Title';

import ButtonsGroup from 'components/ButtonsGroup';

import './style.scss';

const cn = block('download-page');

const DownloadPage = ({ data }) => {
	const { seo, title, description, currentVersion } =
	data.markdownRemark.frontmatter;

	return (
		<Layout seo={seo}>
			<Container className={cn('wrap')}>
				<Heading color='general' weight='bold' className={cn('title')}>
					{title}
				</Heading>
				<div className={cn('content')}>
					<Paragraph>{description}</Paragraph>
					<div className={cn('current-version', { wrap: true })}>
						{/* <Title
							weight='bold'
							level={6}
							className={cn('current-version', { text: true })}
						>
							{currentVersionText}:
						</Title>
						<Title level={6}>{currentVersion}</Title> */}
					</div>
					<ButtonsGroup buttonsData={offlineOnlineVersionSource} />
				</div>
			</Container>
		</Layout>
	);
};

export default DownloadPage;

export const query = graphql`
	query getDownloadPageData {
		markdownRemark(frontmatter: { slug: { eq: "/downloads" } }) {
			frontmatter {
				title
				seo {
					description
					image
					link
					title
				}
				description
				currentVersion
			}
		}
	}
`;
