import { StaticImage } from "gatsby-plugin-image";

const RedArrowRightIcon = ({ className }) => {
  return (
    <StaticImage
      src="../../images/icons/red-arrow-right.svg"
      alt="red-arrow-right"
      placeholder="none"
      layout="fixed"
      loading="eager"
      className={className}
      objectFit="cover"
      aria-hidden={true}
      imgStyle={{ marginLeft: "15%" }}
    />
  );
};

export default RedArrowRightIcon;
