import PropTypes from "prop-types";
import { block } from "bem-cn";

import "./style.scss";

const cn = block("main-title");

const Title = ({
  children,
  className,
  level,
  weight,
  align,
  color,
  isBlock,
}) => {
  return (
    <span
      className={cn({
        level: level,
        weight: weight,
        align: align,
        color: color,
        block: isBlock,
      }).mix(className)}
    >
      {children}
    </span>
  );
};

Title.defaultProps = {
  level: 6,
  align: "left",
  weight: "normal",
  isBlock: false,
};

Title.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  className: PropTypes.object,
  align: PropTypes.string,
  weight: PropTypes.oneOf(["light", "normal", "medium", "bold", "black"]),
  level: PropTypes.number,
  color: PropTypes.oneOf([
    "default",
    "general",
    "primary",
    "secondary",
    "white",
  ]),
  isBlock: PropTypes.bool,
};

export default Title;
